import { types } from "../types/types";

const initialState = []

export const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.loadingList:
            return [
                ...state,
                ...action.payload
            ]
            
        default:
            return state
    }
}