import Swal from 'sweetalert2'

export const useSweetAlert = (msg) => {

    const swalCustom = Swal.mixin({
        customClass: {
          confirmButton: 'btn-confirm-swal',
          title: 'title-swal',
          popup: 'popup-swal',
          icon: 'icon-swal'
        },
        buttonsStyling: false
      })

    swalCustom.fire({
        title: msg.title,
        text: msg.body,
        icon: msg.icon,
      })
}

