import React from 'react'

export const CardVideo = ({link, title, text, text2}) => {
  return (
    <div className='containerVideoNDescription' data-anim='up'>
        <div className='containerDescription'>
            <h3 className='section-title houteCorniche'>{title}</h3>
            <p>{text}</p>
            <p style={{
              marginTop: '-1rem'
            }}>{text2}</p>
        </div>
        <iframe 
            width="560" height="315" 
            src={link} title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
        >
        </iframe>
    </div> 
  )
}
