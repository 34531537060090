export const messages = {
    success: {
        body: 'Su consulta fue enviada correctamente.',
        title: '¡Enviado!',
        icon: 'success'
    },
    error: {
        body: 'Ocurrió un error durante el envío de datos.',
        title: '¡Ups!',
        icon: 'error',
    },
    cantSendMsg: {
        body: 'Ocurrió un error con la conexión. No podrás enviar una consulta.',
        title: 'Ouch!',
        icon: 'error'
    },
    warning: {
        body: '',
        title: '',
        icon: 'warning'
    }
}