import React, { useState } from 'react'

export const ListItem = ({ bar, city, date, img, privated = true}) => {

    let publicUrl = process.env.PUBLIC_URL + '/';

    const [hideInfo, setHideInfo] = useState('hideInfo')

    const handleInfoShow = (e) => {
        e.preventDefault();
        (hideInfo === 'hideInfo') 
        ? setHideInfo('')
        : setHideInfo('hideInfo')
    }

    return (
        <div className={'totalItem ' + hideInfo}>
            <div className='listItem'>
                    <div className='boxItemData'
                        style={{
                            flexBasis: '100%'
                        }}
                    >
                        <h5 className='date'>{date}</h5>
                        <div className='place'>
                            <span>{bar}</span>
                            <span>{city}</span>
                        </div>
                    </div>
                    {/* <button 
                        onClick={handleInfoShow}
                        className='infoBtn'
                    >
                    +Info
                    </button> */}
            </div>
            <div className='info' style={ (privated)? {transform: 'translate(-50%, 145px)'} : {} }>
                {
                    (privated) 
                    ? 
                    <>
                        <p>Evento Privado - Información no disponible</p>
                    </>
                    : 
                    <>
                        <a className='flyer' href={publicUrl+ img} data-rel="lightcase">
                            <img src={publicUrl+ img} alt="" />
                        </a>
                        <div className='map'>
                            <iframe width="100%" height="100%" frameBorder="0" 
                                scrolling="no" marginHeight="0" marginWidth="0" 
                                src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=es&amp;q=Les%20Rambles,%201%20Barcelona,%20Spain+(Mi%20nombre%20de%20egocios)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                    <a href="https://www.gps.ie/car-satnav-gps/">
                                        Car Navigation Systems
                                    </a>
                            </iframe>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
