import React from 'react';
import { useSelector } from 'react-redux';
import { Title } from '../gallery/modules/Title';
import { ListItem } from './modules/ListItem';
import './styles/list.css';
import './styles/schedule.css';

function Availability() {
  
  const list = useSelector( state => state.list)
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div 
      className='select-availability-area pb-120 pt-120' 
      id='schedule'
    >
      <div className="imgSiluet">
        <img src={publicUrl + 'assets/img/siluets/dj-siluet-gold.png'} alt="" />
      </div>
      <Title h6={'Fechas'} h1={'Próximas Presentaciones'}/>
      <div className='containerList'>
        <div className="items">
          {
            list.map( (item, i) => 
              <ListItem 
                key={i} 
                bar={item.bar}
                city={item.city}
                date={item.date}
                img={item.img}
                privated={item.isPrivated}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Availability;
