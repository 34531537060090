import React from 'react'
import { CardVideo } from './modules/CardVideo'
import { Trans, useTranslation } from 'react-i18next'


export const Shows = () => {

    const [ t ] = useTranslation('translation', {keyPrefix:'shows'})


  return (
    <div id='shows' className=''> 
        <div className='backgroundBrown'></div>
        <div className="boxNTitle " >
            <div className='titleNLine textCenter showsTitles' data-anim='left'>
                <h2 className='section-title houteCorniche' >{t('typeshows.title1')}</h2>
                <hr />
                <p id='textCenter'><strong>{t('typeshows.parraph1')}</strong></p>
            </div>
            <div className="boxVideos" >
                <CardVideo 
                    title={t('typeshows.card.title1')}
                    text={'CHILL OUT - DEEP HOUSE'}
                    link={"https://www.youtube.com/embed/cnUnk1ey_Gk"} />
                <div className='boxVideo2'>
                    <CardVideo 
                        title={t('typeshows.card.title2')}
                        text={t('typeshows.card.text1')}
                        link={"https://www.youtube.com/embed/VYeZbiXCKJk"} />
                    <CardVideo 
                        title={t('typeshows.card.title2')}
                        text={t('typeshows.card.text2')}
                        link={"https://www.youtube.com/embed/1KJ35e_Jd6o"} />
                </div>
            </div>
        </div>
        <div className="boxNTitle baruxConcept" >
            <div className='titleNLine textCenter showsTitles' data-anim='right'>
                <h2 className='section-title houteCorniche'>{t('typeshows.title2')}</h2>
                <hr />
                <p id='textCenter'>
                    <Trans i18nKey={'typeshows.parraph2'}>
                        {t('typeshows.parraph2')}
                    </Trans>
                </p>
            </div>
            <div className="boxVideo2" >
                <CardVideo 
                    title={t('typeshows.card.title1')}
                    text={t('typeshows.card.text3')}
                    text2={t('typeshows.card.text4')}
                    link={"https://www.youtube.com/embed/FYEFrU-ytJ8"} />
                <CardVideo 
                    title={t('typeshows.card.title2')}
                    text={t('typeshows.card.text5')}
                    text2={t('typeshows.card.text6')}
                    link={"https://www.youtube.com/embed/aP7N8csce9Y"} />
            </div>
        </div>
    </div>  
  )
}
