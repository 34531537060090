import React, { useEffect } from 'react';
import Social from '../generic/social';
import Copyright from '../generic/copyright';

export const Footer_v1 = () => {

	// Revissar y modifcarlo
  function componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + '/';
    const minscript = document.createElement('script');
    minscript.async = true;
    minscript.src = publicUrl + 'assets/js/main.js';
    document.body.appendChild(minscript);
    $('.go-top')
      .find('a')
      .on('click', function () {
        $('.quarter-overlay').fadeIn(200);
        $(window).scrollTop(0);
        setTimeout(function () {
          $('.quarter-overlay').fadeOut(500);
        }, 500);
      });
    $(document).on('click', '.theme-btn-1 ', function () {
      $('div').removeClass('modal-backdrop');
      $('div').removeClass('show');
      $('div').removeClass('fade');
      $('body').attr('style', '');
    });
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <footer className='ltn__footer-area '>
      <div className='footer-top-area  section-bg-2 plr--5'>
        <div className='container-fluid'>
          <div className='row containerFoot'>
              
              <div className='row footer-widget footer-about-widget '>
                <div className='footer-logo col-sm-6 '>
                  <div className='site-logo'>
                    <img src={publicUrl + 'assets/img/barux/baruxLogo-gold.webp'} alt='Logo' />
                  </div>
                </div>
                <div className='address col-sm-6 '>
                  <div className='footer-address'>
                    <ul>
                      <li>
                        <div className='footer-address-icon'>
                          <i className='icon-placeholder' />
                        </div>
                        <div className='footer-address-info'>
                          <p className='text-start'>Resistencia, Chaco, Argentina</p>
                        </div>
                      </li>
                      <li>
                        <div className='footer-address-icon'>
                          <i className='icon-call' />
                        </div>
                        <div className='footer-address-info'>
                          <p className='text-start'>
                            <a href='tel:+543624-800590'>(+54)3624-800590 (ARG)</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='footer-address-icon'>
                          <i className='icon-call' />
                        </div>
                        <div className='footer-address-info'>
                          <p className='text-start'>
                            <a href='tel:+595993-489785'>(+59)5993-489785 (PY)</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='footer-address-icon'>
                          <i className='icon-mail' />
                        </div>
                        <div className='footer-address-info'>
                          <p className='text-start'>
                            <a href='mailto:baruxoficial@gmail.com'>
                            baruxoficial@gmail.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='ltn__social-media mt-20'>
                    <Social />
                  </div>
                </div>
              </div>
              
              <div className='row boxSiluets '>
                <div className='imgDiv singer'>
                  <img src={publicUrl + 'assets/img/siluets/singer-siluet-gold.png'} alt="" />
                </div>
                <div className='imgDiv dj'>
                  <img src={publicUrl + 'assets/img/siluets/dj-siluet-gold.png'} alt="" />
                </div>
                <div className='imgDiv sax'>
                  <img src={publicUrl + 'assets/img/siluets/sax-siluet-gold.png'} alt="" />
                </div>
              </div>
  
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer_v1;
