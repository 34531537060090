import React, { useEffect } from 'react';
import Navbar from '../modules/navbar/navbar-v4.jsx';
import Video from '../modules/video/video-v4.jsx';
import Footer from '../modules/footer/footer.jsx';
import AboutV1 from '../modules/about/about-v1.jsx';
import Availability from '../modules/schedule/availability.jsx'
import { useDispatch } from 'react-redux';
import { loadListFromDB } from '../redux/actions/list.js';
import { BannerNVideoV1, BannerNVideoV2 } from '../modules/bannerNVideo/index';
import { ContactForm } from '../modules/contact/ContactForm.jsx';
import { useAnimations } from '../hooks/useAnimations.js';
import { Shows } from '../modules/shows/Shows.jsx';
import { init } from '../redux/actions/init.js';
import { Storys } from '../modules/storys/storys.jsx';
import { FloatingActionButton } from '../modules/floatingActionButton/FloatingActionButton.jsx';
import { OurProposals } from '../modules/proposals/ourProposals.jsx';

const Home_V1 = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch( init() )
        dispatch( loadListFromDB() )  
    }, [dispatch])

    useAnimations()

    return <div>
        <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> 
        <Video /> 
        <AboutV1 />

        
        <OurProposals />

        <Shows />

        {/* new section */}
        <Storys />

        {/* <Availability /> */}
        <BannerNVideoV1 />
        <BannerNVideoV2 />
        <ContactForm />
        <Footer />
        <FloatingActionButton />
    </div>
}

export default Home_V1

