import { types } from "../types/types";

const initialState = {
    msg: {
        body: null,
        title: null,
        icon: null,
    },
    loading: false
}


export const uiReducer = ( state = initialState, action ) => {

    switch (action.type) {
        case types.uiLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiMsg:
            return {
                msg: action.payload,
                loading: false,
            }
        
        case types.uiMsgSending:
            return {
                msg: 'Enviando...',
                loading: true
            }
        default:
            return state
    }
}