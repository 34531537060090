import React from 'react';
import Social from '../generic/social';
import { useTranslation } from "react-i18next"

function NavbarV3() {
  let publicUrl = process.env.PUBLIC_URL + '/';

  const [ t, i18n ] = useTranslation('translation', {keyPrefix: 'header.menu'});

  const changeLanguage = () => {
    (i18n.language === 'es') 
    ? i18n.changeLanguage('en') 
    : i18n.changeLanguage('es');
  };

  return (
    <div>
      <header id='header' className='ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2'>
        <div 
          className='ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black' 
          id='navBarResponsive'
        >
          <div className='container'>
            <div className='row rowNavbar'>
              <div className='col colLogo'>
                <div className='site-logo-wrap'>
                  <div className='site-logo go-top'>
                    <a href='/'>
                      <img
                        className='logoNavbar'
                        src={publicUrl + 'assets/img/barux/baruxLogo-gold.webp'}
                        alt='Logo'
                      />
                      <img
                        className='logoNavbar2'
                        src={publicUrl + 'assets/img/barux/logoBarux-navbar-active.webp'}
                        alt='Logo'
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col colSocial">
                  <Social />
              </div>
              <div className='col header-menu-column menu-color-white'>
				  {/* delete "go-top" class */}
                <div className='header-menu d-none d-xl-block'> 
                  <nav>
                    <div className='ltn__main-menu'>
                      <ul>
                        <li className=''>
                          <a href='#home'>{t('home')}</a>
                        </li>

                        <li className=''>
                          <a href='#about'>{t('about')}</a>
                        </li>

                        <li className=''>
                          <a href='#shows'>{t('shows')}</a>
                        </li>

                        <li className=''>
                          <a href='#story'>{t('story')}</a>
                        </li>

                        {/* <li className=''>
                          <a href='#imagesNvideos'>Galeria</a>
                        </li> */}

                        <li className='special-link'>
                          <a href='#contact' id='linkContact'>{t('contact')}</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className='col--- ltn__header-options ltn__header-options-2 '>
                {/* Mobile Menu Button */}
                <div className='mobile-menu-toggle d-xl-none'>
                  <a
                    href='#ltn__utilize-mobile-menu'
                    className='ltn__utilize-toggle'
                    id='toogleMenu'
                  >
                    <svg viewBox='0 0 800 600'>
                      <path
                        d='M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200'
                        id='top'
                      />
                      <path d='M300,320 L540,320' id='middle' />
                      <path
                        d='M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190'
                        id='bottom'
                        transform='translate(480, 320) scale(1, -1) translate(-480, -318) '
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
      </header>

      <div
        id='ltn__utilize-mobile-menu'
        className='ltn__utilize ltn__utilize-mobile-menu menuResponsive'
      >
        <div className='ltn__utilize-menu-inner ltn__scrollbar'>
          <div className='ltn__utilize-menu-head '>
            <div className='site-logo logoMenu'>
              <a href='/'>
                <img 
                  className=''
                  src={publicUrl + 'assets/img/barux/baruxLogo-gold.webp'} 
                  alt='Logo' />
              </a>
            </div>
            <button className='ltn__utilize-close'>×</button>
          </div>
          <div className='ltn__utilize-menu'>
            <ul>
              {/* <li className=''>
                <button
                  onClick={changeLanguage}
                >
                  { (i18n.language === 'es') ? 'EN' : 'ES'}
                </button>
              </li> */}
              <li className=''>
                <a href='#home'>{t('home')}</a>
              </li>

              <li className=''>
                <a href='#about'>{t('about')}</a>
              </li>

              <li className=''>
                <a href='#shows'>{t('shows')}</a>
              </li>

              <li className=''>
                <a href='#story'>{t('story')}</a>
              </li>

              {/* <li className=''>
                <a href='#imagesNvideos'>Galeria</a>
              </li> */}

              <li className=''>
                <a href='#contact'>{t('contact')}</a>
              </li>
            </ul>
          </div>
          
          <div className='ltn__social-media-2 socialMedia'>
            <ul>
              <li>
                <a href='https://www.facebook.com/barux.oficial' title='Facebook'>
                  <i className='fab fa-facebook-f' />
                </a>
              </li>
              {/* <li>
                <a href='#' title='Twitter'>
                  <i className='fab fa-twitter' />
                </a>
              </li> */}
              <li>
                <a href='https://www.instagram.com/barux.oficial/' title='Instagram'>
                  <i className='fab fa-instagram' />
                </a>
              </li>
              <li>
                <a title='Dribbble' onClick={changeLanguage} style={{cursor: 'pointer'}}>
                  { (i18n.language === 'es') ? 'EN' : 'ES'}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarV3;
