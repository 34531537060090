import { formatList } from "../../format/formatList";
import { types } from "../types/types";

export const loadListFromDB = () => {
    return (dispatch) => {
        const list = formatList()
        dispatch( setListOnTree(list) )
    }
}

const setListOnTree = (list) => ({
    type: types.loadingList,
    payload: list
})