import axios from "axios"
import { uid } from 'uid';


const generateIp =  () => {
    const nums = '0123456789';
    const generateNumbersString = (length) => {
        let result = '';
        const charactersLength = nums.length;
        for (let i = 0; i < length; i++) {
        result += nums.charAt(Math.floor(Math.random() * charactersLength));}
        return result;
    }
    let ip;
    // try {
    //     ip = await publicIpv4()
    // } catch (error) {
        ip = `192.010.${generateNumbersString(3)}.${generateNumbersString(3)}`;
    // }
    return ip
}

const ip = generateIp()

export const fetchData = async ( method, url, data, commerceId = '' ) => {


    const axiosInstance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                ...(sessionStorage.getItem('sng') && { 'sng': sessionStorage.getItem('sng') } ),
                ...(sessionStorage.getItem('ct') && { 'csrf-token': sessionStorage.getItem('ct') } )
            },
            baseURL: process.env.REACT_APP_URL_BASE
    })
    
    const response = await axiosInstance({
        method,
        url: url + `${commerceId}`,
        ...( data && {data} )
    })
    
    return response
}


