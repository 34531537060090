import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

function AboutV1() {


  const [ t ] = useTranslation('translation', { keyPrefix: 'about' })

  
  return (
    <div className='ltn__about-us-area ' id='about'>
      <div className="backgroundBlack">

          <div className='backgroundAbout_gradient'>
            <div className='col-lg-6 align-self-center text'>
              <div className={'about-us-info-wrap '} data-anim="left" >
                <div className='section-title-area ltn__section-title-2--- mb-20 ' >
                  <h6 className='section-subtitle section-subtitle-2 ltn__secondary-color' >
                    {t('about')}
                  </h6>
                  <h1 className='section-title houteCorniche'>
                    {t('title')}
                  </h1>
                  <p className='text-start'>
                    <Trans i18nKey={'p'}>
                      {t('p')}
                    </Trans>
                  </p>
                  <h2 className='section-title mt-4 houteCorniche'>
                    {t('subtitle')}
                  </h2>
                  <p className='text-start'>
                    <Trans i18nKey={'p'}>
                      {t('p2')}
                    </Trans>
                  </p>
                </div>
                <ul className='team-list'>
                  <li>
                    <Icon icon="entypo:modern-mic" />
                    <strong>Diana Dii Rizzi</strong>
                  </li>
                  <li>
                    <Icon icon="fluent-emoji-high-contrast:saxophone" />
                    <strong>Pepo Garcia</strong>
                  </li>
                  <li>
                    <Icon icon="icon-park-outline:headphone-sound" />
                    <strong>Leandro Peino</strong>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default AboutV1;
