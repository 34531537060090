import React from 'react'

export const Title = ({h6, h1}) => {
     
  return (
    <div 
        className='section-title-area ltn__section-title-2--- titleShows'
    >
      <h6 className='section-subtitle section-subtitle-2 ltn__secondary-color shows__subtitle'>
          {h6}
      </h6>
      <h1 className='section-title'>{h1}</h1>
    </div>
  )
}
