import React, { useEffect, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { dataSend } from '../../redux/actions/dataSend';
import { validateForm } from '../../helpers/helpers';
import { MessagesOnForm } from './modules/MessagesOnForm';
import { upperCamelCase } from '../../helpers/capitalize';
import { useTranslation } from 'react-i18next';

export const ContactForm = () => {

	const [ t ] = useTranslation('translation', { keyPrefix: 'contact' })

	const dispatch = useDispatch();
	const { msg, loading } = useSelector(state => state.ui)
	const [ values, handleInputChange, reset ] = useForm({
		name: '',
		matter: '',
		email: '',
		phone: '',
		message: ''
	})

	const [showMsg, setShowMsg] = useState(false)
	const [isValidate, setIsValidate] = useState(false)

	useEffect(() => {
		setIsValidate( validateForm( values )[0] )
	}, [ values ])
	

	const handleSubmitForm = (e) => {
		e.preventDefault();
		const { name } = values
		values.name = upperCamelCase(name) 
		dispatch( dataSend(values) );
		reset();
	}

    return <div id='contact' className="ltn__contact-message-area pt-50 pb-50">
				<div className="container">
					<div className="row contactForm">
						<div className="col-lg-8">
							<div className="ltn__form-box contact-form-box box-shadow transparent-bg">
								<h4 className="title-2">{t('title')}</h4>
								<form id="contact-form" onFocus={ () => setShowMsg(true) } >
									<div className="row">
										<div className="col-md-6">
											<div className="input-item input-item-name ltn__custom-icon">
												<input type="text" name="name" placeholder={t('name')} value={values.name} onChange={handleInputChange} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="input-item input-item-matter ltn__custom-icon">
												<input type="text" name="matter" placeholder={t('matter')} value={values.matter} onChange={handleInputChange} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="input-item input-item-email ltn__custom-icon">
												<input type="email" name="email" placeholder={t('email')} value={values.email} onChange={handleInputChange} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="input-item input-item-phone ltn__custom-icon">
												<input type="text" name="phone" placeholder={t('phone')} value={values.phone} onChange={handleInputChange} />
											</div>
										</div>
									</div>
									<div className="input-item input-item-textarea ltn__custom-icon">
										<textarea name="message" placeholder={t('message')} value={values.message} onChange={handleInputChange} />
									</div>
									
									<div className="btn-wrapper mt-0">
										<button className="btn theme-btn-1 btn-effect-2 text-uppercase" onClick={handleSubmitForm} disabled={!isValidate} type="submit">{t('button')}</button>
									</div>
									<MessagesOnForm 
										showMsg={showMsg} 
										loading={loading} 
										msg={msg} 
										validateForm={validateForm(values)[1]}
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
    }
