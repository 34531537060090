import React from 'react'
import { useTranslation } from 'react-i18next';

export const FloatingActionButton = () => {
    
    const { i18n } = useTranslation()

    const changeLanguage = () => {
        (i18n.language === 'es') 
        ? i18n.changeLanguage('en') 
        : i18n.changeLanguage('es');
      };

    return (
        <button
            id="translation-button"
            onClick={changeLanguage}
        >
            <p>
                { 
                    (i18n.language === 'es') 
                    ? 'English Version' 
                    : 'Versión en Español'
                }
            </p>
        </button>
    )
}
