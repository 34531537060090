import { fetchData } from "../../services/fetchdata"
import { useSweetAlert } from "../../hooks/useSweetAlert";
import { uiMsg } from "./ui"
import { messages } from "../../mocks/messages";
import { saveSessionStorage } from "../../helpers/helpers";


export const init = () => async dispatch => {

    const resp = await fetchData( 'GET', 'control' ).catch( () => {
        dispatch( uiMsg(messages.cantSendMsg) )
        useSweetAlert(messages.cantSendMsg)
    })
    if(resp) {
        saveSessionStorage(resp.data['_sng'], 'sng');
        saveSessionStorage(resp.data['token'], 'ct');
    }
}