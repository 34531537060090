import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose } from "redux";
import { listReducer } from "../reducers/listReducer";
import thunk from 'redux-thunk'
import { uiReducer } from "../reducers/uiReducer";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = {
    reducer: {
        list: listReducer,
        ui: uiReducer
    }
}

export const store = configureStore( 
    reducers, 
    composeEnhancers(
        applyMiddleware(thunk)
    )
);