import { useSweetAlert } from "../../hooks/useSweetAlert";
import { messages } from "../../mocks/messages";
import { fetchData } from "../../services/fetchdata";
import { uiMsg, uiMsgSending } from "./ui"


let comid = process.env.REACT_APP_COMMERCE_ID


export const dataSend = (values) =>  async dispatch => {


    dispatch( uiMsgSending() )

    const resp = await fetchData( 'POST', 'contact/', values, comid).catch( () => {
        dispatch( uiMsg(messages.error) )
        useSweetAlert(messages.error)
    })
    if(resp){
        dispatch( uiMsg(messages.success) )
        useSweetAlert(messages.success)
    }
}