import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import HomeV4 from './pages/home-v4.jsx';

const app = () => {
  
  return (
    <Router>
        <Switch>
          <Route exact path='/' component={HomeV4} />
        </Switch>
    </Router>
  );
};

export default app;
