import { useEffect } from "react"

const elementToAnimate = ( coi = '#quarter' ) => document.querySelectorAll(coi) 
const cargarImagen = (into) => {
    into.forEach( e => {
        let className
        if(!!e.target.attributes['data-anim'].nodeValue) {
            switch (e.target.attributes['data-anim'].nodeValue) {
                case 'left':
                    className = 'opacityNoneLeft'
                    break;
                case 'right':
                    className = 'opacityNoneRight'
                    break;
                case 'up':
                    className = 'opacityNoneUp'
                    break;
                case 'down':
                    className = 'opacityNoneDown'
                    break;
                default:
                    break;
            }
        }
        ( e.isIntersecting ) 
        ? e.target.classList.remove(className)
        : e.target.classList.add(className)
    });
}

const observer = new IntersectionObserver( cargarImagen, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
})

export const useAnimations = () => {
    
    const watch = (side) => elementToAnimate(`*[data-anim="${side}"]`).forEach( e => observer.observe(e) )
    const animationsTypes = ['up', 'right', 'down', 'left']

    useEffect(() => {
        animationsTypes.forEach( e => watch(e))
    }, [animationsTypes])
}