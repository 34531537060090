import React from "react";
import { useTranslation } from "react-i18next";

export const BannerNVideoV2 = () => {

  const [ t ] = useTranslation('translation', { keyPrefix: 'fest' })

  return (
    <div className='v2 bannerNVideo'>
      <h1 className='section-title houteCorniche' data-anim='right'>{t('title')}</h1>
      <div className='backgroundImage'></div>
      <div className='backgroundBrown'></div>

      <div className='onSide' data-anim='left'>
        <iframe src="https://www.youtube.com/embed/RVzJp43Dl-Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className='textVideo'>
          <p>{t('parraph')}</p>
        </div>
      </div>
      
    </div>
  );
}