import React from 'react';
function VideoV4() {
  let publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div id='home' className='ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg'>
      <div className='ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active'>
        {/* HTML5 VIDEO */}
        <video autoPlay muted loop id='myVideo'>
          {
            (window.screen.width > 720) 
            ? <source src={publicUrl + 'assets/media/3.webm'} type='video/mp4' />
            : <source src={publicUrl + 'assets/media/4.webm'} type='video/mp4' />
          }
        </video>
        <div
          className='ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-10---'
          data-bs-bg='img/slider/41.jpg'
        >
          <div className='ltn__slide-item-inner text-center'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 align-self-center'>
                  <div className='slide-item-info'>
                    <div className='slide-item-info-inner ltn__slide-animation'>
                      <h1 className='slide-title text-uppercase white-color animated houteCorniche' id='baruxMusic' data-anim='down'>
                      Barux Ibiza style
                      </h1>
                      {/* <h1 className='slide-title liveSaxProject'>Live Sax Project</h1> */}
                      <h2 className='slide-subtitle text-uppercase white-color houteCorniche'><span className='sax'>Live</span><span className='live'>Sax</span><span className='project'>Project</span></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoV4;
