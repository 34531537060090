import React from 'react';
import './styles/storys.css'
import { useTranslation } from 'react-i18next';

export const Storys = () => {

    let publicUrl = process.env.PUBLIC_URL + '/';
    
    const [ t ] = useTranslation('translation', { keyPrefix: 'stories' })

    const addAtributte = () => {
        let videoOnPlay
        setTimeout(() => {
            videoOnPlay = document.querySelector('#lightcase-content .lightcase-contentInner video')
            videoOnPlay.setAttribute('loop', true)
        }, 3000);
    }

    return (
        <div id='story' className="section-stories">
            <div className='section-stories__title'>
                <hr />
                <h5>
                    {t('title')}
                </h5>
            </div>
            <div className="stories-container">

                <div className="story-box">
                    <a className="story-image" href={publicUrl+"assets/media/story-1.webm"}  data-rel="lightcase:myCollection" onClick={ addAtributte }>
                        <img src={publicUrl + 'assets/img/barux/story-1.jpg'} alt="Image" />
                    </a>
                </div>

                <div className="story-box">
                    <a className="story-image" href={publicUrl+"assets/media/story-2.webm"}  data-rel="lightcase:myCollection">
                        <img src={publicUrl + 'assets/img/barux/story-2.jpg'} alt="Image" />
                    </a>
                </div>

                <div className="story-box">
                    <a className="story-image" href={publicUrl+"assets/media/story-3.webm"}  data-rel="lightcase:myCollection">
                        <img src={publicUrl + 'assets/img/barux/story-3.jpg'} alt="Image" />
                    </a>
                </div>

                <div className="story-box">
                    <a className="story-image" href={publicUrl+"assets/media/story-4.webm"}  data-rel="lightcase:myCollection">
                        <img src={publicUrl + 'assets/img/barux/story-4.jpg'} alt="Image" />
                    </a>
                </div>

            </div>
            <hr />
        </div>
    )
}
