import React from 'react'

export const MessagesOnForm = ({ showMsg, loading, msg, validateForm }) => {
  return (
    <>
        {
            (showMsg) 
            ? (loading) 
                ? <p style={{
                        color: '#f5d88a',
                        marginTop: '1rem',
                        textAlign: 'start'
                    }}>{msg}</p>
                : validateForm.map( (p, i) => 
                    <p key={i} className="formMessage">{p}</p>
                )
            : <p className="formMessage"></p>
        }
    </>
  )
}
