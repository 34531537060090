import React, { Component } from 'react';

class CopyRight extends Component {

    render() {

        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row">
				<div className="col-md-6 col-12">
					<div className="ltn__copyright-design clearfix">
					<p>All Rights Reserved @ Barux <span className="current-year" /></p>
					</div>
				</div>
				<div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
						<p>
							Powered by <strong><a href="https://ding.com.ar">Ding Technology</a></strong>
						</p>
					</div>
				</div>
				</div>
			</div>
			</div>
        )
    }
}


export default CopyRight