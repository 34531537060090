import { upperCamelCase } from "../helpers/capitalize"

export const formatList = () => {
    let arrayDate = []
    
    arrayDate.push(
        {
            bar: 'Evento Privado',
            city: 'Corrientes - Corrientes - Argentina',
            date: 'Vie 2 Dic',
            img: 'assets/img/barux/shows4.png',
            isPrivated: true
        },
        {
            bar: 'Jack Tower',
            city: 'Corrientes - Capital - Argentina',
            date: 'Dom 4 Dic',
            img: 'assets/img/barux/shows4.png'
        },
        {
            bar: 'Evento Privado',
            city: 'Saenz Peña - Chaco - Argentina',
            date: 'Lun 5 Dic',
            img: 'assets/img/barux/shows4.png'
        },
        {
            bar: 'Londoner',
            city: 'Asunción - Paraguay',
            date: 'Mie 7 Dic',
            img: 'assets/img/barux/shows2.png'
        },
        {
            bar: 'Evento Privado',
            city: 'Resistencia - Chaco - Argentina',
            date: 'Jue 8 Dic',
            img: 'assets/img/barux/shows4.png',
            isPrivated: true
        },
        {
            bar: 'Evento Privado',
            city: 'Corrientes - Argentina',
            date: 'Vie 9 Dic',
            img: 'assets/img/barux/shows4.png'
        },
        {
            bar: 'Temple Bar',
            city: 'Corrientes - Argentina',
            date: 'Sab 10 Dic',
            img: 'assets/img/barux/shows4.png'
        },
        {
            bar: 'Mollys',
            city: 'Asunción - Paraguay',
            date: 'Jue 15 Dic',
            img: 'assets/img/barux/shows4.png'
        },
        {
            bar: 'Casamiento',
            city: 'Corrientes - Argentina',
            date: 'Sab 17 Dic',
            img: 'assets/img/barux/shows2.png'
        },
        {
            bar: 'Gira por la costa argentina',
            city: 'Mar del Plata, Pinamar y Capital Federal',
            date: '17 - 31 Ene (2023)',
            img: 'assets/img/barux/shows2.png'
        },
        {
            bar: 'Evento Privado',
            city: 'Resistencia - Chaco - Argentina',
            date: 'Sab 4 Mar (2023)',
            img: 'assets/img/barux/shows2.png'
        },
        {
            bar: 'Evento Privado',
            city: 'Quitilipi - Chaco - Argentina',
            date: 'Sab 4 Mar (2023)',
            img: 'assets/img/barux/shows2.png'
        },
        {
            bar: 'Evento Privado',
            city: 'Charata - Chaco - Argentina',
            date: 'Sab 11 Mar (2023)',
            img: 'assets/img/barux/shows2.png'
        }
    )

    const list = arrayDate.map( item => ({ 
        bar: upperCamelCase(item.bar),
        city: upperCamelCase(item.city),
        date: item.date,
        img: item.img
    }))

    return list
}