import React from 'react';
import { useTranslation } from 'react-i18next';


export const BannerNVideoV1 = () => {

  const [ t ] = useTranslation('translation', { keyPrefix: 'sinfonic' })
  

  return (
    <div className='bannerNVideo' >

      <h1 className='section-title houteCorniche' data-anim='left'>{t('title')}</h1>
      <div className=' backgroundImage'></div>
      <div className='backgroundBrown'></div>

      <div className='onSide' data-anim='right'>
        <iframe src="https://www.youtube.com/embed/zh1-RkVqJLg" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <div className='textVideo'>
          <p>{t('parraph')}</p>

        </div>
      </div>
   

    </div>
  );
}

