import React from 'react';
import { useTranslation } from 'react-i18next';

function Social() {

  const [ t, i18n ] = useTranslation('translation', {keyPrefix: 'header.menu'});

  const changeLanguage = () => {
    (i18n.language === 'es') 
    ? i18n.changeLanguage('en') 
    : i18n.changeLanguage('es');
  };


  return (
    <div className='ltn__social-media'>
      <ul
        id='social-translations'
      >
        <li>
          <a href='https://www.facebook.com/barux.oficial' title='Facebook'>
            <i className='fab fa-facebook-f' />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/barux.oficial/' title='Instagram'>
            <i className='fab fa-instagram' />
          </a>
        </li>
        {/* <li id='translation-button'>
          <a title='Dribbble' onClick={changeLanguage} style={{cursor: 'pointer', fontSize: '1.3rem'}}>
            { (i18n.language === 'es') ? 'EN' : 'ES'}
          </a>
        </li> */}
      </ul>
    </div>
  );
}

export default Social;
