import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const OurProposals = () => {

    const [ t ] = useTranslation('translation', {keyPrefix:'shows'})

    return (
        <div className='nuestrasPropuestas' >
            <h2 className='section-title houteCorniche textLeft' data-anim='left'>
                {t('proposals.title')}
            </h2>
            <p className='' data-anim='right'>{t('proposals.parraph')}</p>
            <div className='propuestas-cuadros d-flex g-2 justify-content-evenly mt-5' data-anim='up'>
                <div className='baruxBoxProp baruxBanda'>
                    <h3 className='houteCorniche section-title'>{t('proposals.prop1')}</h3>
                    <p>
                        <Trans i18nKey={'proposals.prop1Parraph'}>
                            {t('proposals.prop1Parraph')}
                        </Trans>
                    </p>
                </div>
                <div className='baruxBoxProp baruxConcept'>
                    <h3 className='houteCorniche section-title'>{t('proposals.prop2')}</h3>
                    <p>{t('proposals.prop2Parraph')}</p>
                </div>
            </div>

        </div>
    )
}
